<template>
  <div class="questionnaire">
    <div>
      <h2 class="question-title" v-if="currentIndex === 0">
        一、以下问题与某些痛苦和问题有关，在过去 30
        天内可能困扰您。如果您觉得问题适合您的情况，并在过去 30
        天内存在，请回答"是"。另一方面，如果问题不适合您的情况或在过去30天内不存在，请回答"否"。在回答问卷时请不要与任何人讨论，如您不能确定该如何回答问题，请尽量给出您认为的最恰当回答。
      </h2>

      <div v-if="currentQuestion" class="question-section">
        <span class="progress-text"
          >{{ currentIndex + 1 }}/{{ questions.length }}</span
        >
        <div class="progress-bar">
          <div class="progress" :style="{ width: progressWidth }"></div>
        </div>

        <div class="question">
          <h3 class="question-title">
            {{ currentQuestion.subject || currentQuestion.title }}
          </h3>

          <!-- 单选题 -->
          <div v-if="currentQuestion.type === 'radio'" class="options">
            <div
              v-for="(option, index) in parsedOptions"
              :key="index"
              class="option"
            >
              <input
                type="radio"
                :id="'option-' + currentQuestion.id + '-' + index"
                :value="option"
                :checked="answers[currentQuestion.id] === option"
                @change="handleRadioChange(option)"
                class="radio-input"
                :name="'radio-group-' + currentQuestion.id"
              />
              <label
                :for="'option-' + currentQuestion.id + '-' + index"
                class="radio-label"
              >
                {{ option }}
              </label>
            </div>
          </div>

          <!-- 多选题 -->
          <div v-if="currentQuestion.type === 'checkbox'" class="options">
            <div
              v-for="(option, index) in parsedOptions"
              :key="index"
              class="option"
            >
              <input
                type="checkbox"
                :id="'checkbox-' + currentQuestion.id + '-' + index"
                :value="option"
                :checked="isCheckboxChecked(option)"
                @change="handleCheckboxChange($event, option)"
                class="checkbox-input"
              />
              <label
                :for="'checkbox-' + currentQuestion.id + '-' + index"
                class="checkbox-label"
              >
                {{ option }}
              </label>
            </div>
          </div>

          <!-- 下拉框 -->
          <div
            v-if="currentQuestion.type === 'select'"
            class="select-container"
          >
            <select
              v-model="answers[currentQuestion.id]"
              @change="saveAnswer"
              class="custom-select"
            >
              <option value="">请选择</option>
              <option
                v-for="(option, index) in parsedOptions"
                :key="index"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>

          <input
            type="text"
            class="qita"
            v-if="currentIndex === 26"
            v-model="xingming"
            placeholder="请输入姓名"
            @input="savePersonalInfo"
          />
          <input
            type="text"
            class="qita"
            v-if="currentIndex === 27"
            v-model="gonghao"
            placeholder="请输入工号"
            @input="savePersonalInfo"
          />
          <input
            type="text"
            class="qita"
            v-if="currentIndex === 29"
            v-model="nianling"
            placeholder="请输入年龄"
            @input="savePersonalInfo"
          />
          <textarea
            type="text"
            class="qita2"
            v-if="currentIndex === 25"
            v-model="kunrao"
            placeholder="请输入您的困扰"
            @input="savePersonalInfo"
          />
        </div>

        <div class="navigation">
          <button
            @click="prevQuestion"
            :disabled="currentIndex === 0"
            class="nav-button"
          >
            上一题
          </button>
          <button
            v-if="currentIndex < questions.length - 1"
            @click="nextQuestion"
            class="nav-button"
          >
            下一题
          </button>
          <button v-else @click="submitAnswers" class="submit-button">
            提交
          </button>
        </div>
      </div>
      <div v-else class="loading">
        <p>加载中...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  data() {
    return {
      questions: [],
      currentIndex: 0,
      answers: {},
      nianling: "",
      kunrao: "",
      gonghao: "",
      xingming: "",
      xingbie: "",
      personalInfoArray: [],
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentIndex];
    },
    progressWidth() {
      return this.questions.length > 0
        ? ((this.currentIndex + 1) / this.questions.length) * 100 + "%"
        : "0%";
    },
    parsedOptions() {
      if (!this.currentQuestion) return [];

      try {
        if (this.currentQuestion.answer) {
          const parsed = JSON.parse(this.currentQuestion.answer);
          return parsed.value || [];
        } else if (this.currentQuestion.item) {
          return this.currentQuestion.item.map((option) => option.name);
        }
        return [];
      } catch (e) {
        console.error("解析选项失败:", e);
        return [];
      }
    },
  },
  methods: {
    // 初始化多选题答案数组
    initCheckboxAnswers() {
      this.questions.forEach((question) => {
        if (
          question.type === "checkbox" &&
          !Array.isArray(this.answers[question.id])
        ) {
          this.$set(this.answers, question.id, []);
        }
      });
    },

    // 单选处理
    handleRadioChange(option) {
      this.$set(this.answers, this.currentQuestion.id, option);
      this.saveAnswer();
    },

    // 多选处理
    isCheckboxChecked(option) {
      const currentAnswer = this.answers[this.currentQuestion.id] || [];
      return currentAnswer.includes(option);
    },

    handleCheckboxChange(event, option) {
      let currentAnswer = [...(this.answers[this.currentQuestion.id] || [])];
      const optionIndex = currentAnswer.indexOf(option);

      if (event.target.checked) {
        if (optionIndex === -1) {
          currentAnswer.push(option);
        }
      } else {
        if (optionIndex !== -1) {
          currentAnswer.splice(optionIndex, 1);
        }
      }

      // 特殊题目处理
      if ([4, 31].includes(this.currentIndex)) {
        if (option === "无不适感觉" || option === "以上均无") {
          this.$set(this.answers, this.currentQuestion.id, [option]);
          return;
        } else {
          // 移除特殊选项
          currentAnswer = currentAnswer.filter(
            (opt) => opt !== "无不适感觉" && opt !== "以上均无"
          );
        }
      }

      this.$set(this.answers, this.currentQuestion.id, currentAnswer);
      this.saveAnswer();
    },

    // 保存个人信息
    savePersonalInfo() {
      this.answers["140"] = this.nianling;
      this.answers["137"] = this.xingming;
      this.answers["138"] = this.gonghao;
      this.answers["136"] = this.kunrao || "未填";
      this.saveAnswer();
    },

    // 加载问题
    async fetchQuestions() {
      try {
        const response = await fetch(
          "https://assess.eapchina.net/getQuestions888"
        );
        const data = await response.json();
        this.questions = Object.values(data.data).flat();

        // 初始化多选题答案
        this.initCheckboxAnswers();

        // 重新排序问题
        const part10Questions = this.questions.filter((q) => q.part === 11);
        const otherQuestions = this.questions.filter((q) => q.part !== 11);
        this.questions = [...otherQuestions, ...part10Questions];

        this.loadProgress();
      } catch (error) {
        console.error("加载题目失败:", error);
        Toast.fail("加载题目失败，请重试");
      }
    },

    // 获取个人信息
    async getpersonalInfoArray() {
      const userId = localStorage.getItem("userId");
      try {
        const response = await fetch(
          `https://assess.eapchina.net/getPersonalInfo4HW?userId=${userId}`
        );
        const data = await response.json();
        this.personalInfoArray = data.data;

        // 填充个人信息
        this.xingming = this.personalInfoArray[0] || "";
        this.gonghao = this.personalInfoArray[1] || "";
        this.xingbie = this.personalInfoArray[2] || "";
        this.nianling = this.personalInfoArray[3] || "";

        // 设置单选答案
        const radioQuestions = {
          139: this.xingbie,
          141: this.personalInfoArray[4] || "",
          142: this.personalInfoArray[5] || "",
          143: this.personalInfoArray[6] || "",
          144: this.personalInfoArray[7] || "",
          145: this.personalInfoArray[8] || "",
          146: this.personalInfoArray[9] || "",
        };

        Object.entries(radioQuestions).forEach(([id, value]) => {
          if (value) this.$set(this.answers, id, value);
        });

        this.loadProgress();
      } catch (error) {
        console.error("获取个人信息失败:", error);
      }
    },

    // 保存答案到本地存储
    saveAnswer() {
      localStorage.setItem("answers", JSON.stringify(this.answers));
    },

    // 加载进度
    loadProgress() {
      const savedAnswers = localStorage.getItem("answers");
      if (savedAnswers) {
        // 使用深拷贝避免引用问题
        this.answers = JSON.parse(JSON.stringify(JSON.parse(savedAnswers)));
      }

      // 查找第一个未回答的问题
      this.currentIndex = this.questions.findIndex((question) => {
        const answer = this.answers[question.id];

        if (question.type === "checkbox") {
          return !answer || answer.length === 0;
        }

        return answer === null || answer === undefined || answer === "";
      });

      this.currentIndex = this.currentIndex === -1 ? 0 : this.currentIndex;
    },

    // 下一题
    nextQuestion() {
      // 验证当前题目
      const currentAnswer = this.answers[this.currentQuestion.id];

      if (
        (this.currentQuestion.type === "radio" && !currentAnswer) ||
        (this.currentQuestion.type === "checkbox" &&
          (!currentAnswer || currentAnswer.length === 0)) ||
        (this.currentQuestion.type === "select" && !currentAnswer)
      ) {
        Toast.fail("请选择选项后再继续！");
        return;
      }

      // 特殊逻辑处理
      if (this.answers["144"] === "员工" && this.currentIndex === 33) {
        this.currentIndex += 2; // 跳过员工不需要回答的问题
        return;
      }

      if (this.answers["142"] === "已婚" && this.currentIndex === 31) {
        this.currentIndex += 2; // 跳过已婚不需要回答的问题
        return;
      }

      // 正常下一题
      if (this.currentIndex < this.questions.length - 1) {
        this.currentIndex++;
        this.$nextTick(() => {
          this.scrollToTop();
        });
      }

      this.saveAnswer();
    },

    // 上一题
    prevQuestion() {
      let targetIndex = this.currentIndex - 1;

      // 特殊逻辑处理
      if (this.answers["144"] === "员工" && targetIndex === 34) {
        targetIndex--; // 跳过员工不需要回答的问题
      }

      if (this.answers["142"] === "已婚" && targetIndex === 32) {
        targetIndex--; // 跳过已婚不需要回答的问题
      }

      if (targetIndex >= 0) {
        this.currentIndex = targetIndex;
        this.$nextTick(() => {
          this.scrollToTop();
        });
      }

      this.saveAnswer();
    },

    // 滚动到顶部
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    // 提交答案
    async submitAnswers() {
      const unansweredIndex = this.questions.findIndex((question) => {
        const answer = this.answers[question.id];

        if (question.type === "checkbox") {
          return !answer || answer.length === 0; // 多选题未选中
        }

        // 单选和下拉框检查是否为空（区分 0 和未选择）
        if (question.type === "select" || question.type === "radio") {
          return answer === null || answer === undefined || answer === "";
        }

        // 其他题型的默认检查
        return answer === null || answer === undefined || answer === "";
      });

      if (
        unansweredIndex !== -1 &&
        this.answers["143"] &&
        this.answers["145"]
      ) {
        // 有未完成的题目，跳转到第一道未完成的题目
        this.currentIndex = unansweredIndex;
        Toast.fail(`请完成第 ${unansweredIndex + 1} 题后再提交！`);
        return;
      }

      // 数据处理
      const daan = {};
      for (const [questionId, answer] of Object.entries(this.answers)) {
        const question = this.questions.find((q) => q.id == questionId);
        if (!question) continue;

        if (question.lbassessment_id) {
          // 处理 `lb3519-9839` 格式
          const selectedOption = question.item.find(
            (option) => option.name === answer || option.id == answer
          );
          if (selectedOption) {
            daan[`lb${question.lbassessment_id}-${question.id}`] =
              selectedOption.id.toString();
          }
        } else {
          // 处理 `vote-0` 格式
          const voteKey = `vote-${questionId}`;
          if (Array.isArray(answer)) {
            // 多选题的答案拼接成逗号分隔的字符串
            daan[voteKey] = answer.join(",");
          } else {
            // 单选题或输入框答案直接使用
            daan[voteKey] = answer;
          }
        }
      }

      daan["userId"] = localStorage.getItem("userId");
      console.log("提交的答案：", daan);

      Toast.loading({
        message: "正在计算结果",
        forbidClick: true,
        duration: 0,
      });

      try {
        const response = await fetch(
          "https://assess.eapchina.net/saveTheAnswer4HW",
          // "http://192.168.1.217:8081/assess/saveTheAnswer4HW",
          {
            method: "POST",
            body: JSON.stringify(daan),
          }
        );

        if (!response.ok) {
          throw new Error(`提交失败，状态码：${response.status}`);
        }

        const result = await response.json();
        Toast.success("提交成功");
        localStorage.setItem("voteLogId", result.voteLogId); // 清理缓存数据
        setTimeout(() => {
          this.$router.push("/CP/huawei/answerOver");
        }, 1000);
        localStorage.removeItem("answers"); // 清理缓存数据
      } catch (error) {
        Toast.fail("提交失败");
      }
    },
  },

  created() {
    this.fetchQuestions();
    this.getpersonalInfoArray();
  },
};
</script>

<style scoped>
.questionnaire {
  font-family: "Arial", sans-serif;
  max-width: 720px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  /* background: #fff; */
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
}

.title {
  font-size: 1.8em;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.progress-bar {
  position: relative;
  background: #e0e0e0;
  height: 8px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;
}

.progress {
  background: #76c7c0;
  height: 100%;
}

.progress-text {
  font-size: 0.9em;
  margin-top: 8px;
  display: block;
  text-align: center;
}

.question-title {
  font-size: 1.2em;
  margin-bottom: 15px;
  color: #444;
  line-height: 30px;
  margin-bottom: 30px;
}

.options {
  display: flex;
  flex-direction: column;
}

.option {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.radio-input,
.checkbox-input {
  margin-right: 10px;
}

.radio-label,
.checkbox-label {
  font-size: 1em;
  color: #555;
}

.select-container {
  margin-top: 10px;
}

.custom-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  color: #555;
  background: #f9f9f9;
}

.navigation {
  margin-top: 20px;
  text-align: center;
}

.nav-button,
.submit-button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  background-color: #76c7c0;
  color: white;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s;
}

.nav-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.nav-button:hover:not(:disabled),
.submit-button:hover {
  background-color: #5ba9a1;
}

@media (max-width: 768px) {
  .questionnaire {
    padding: 15px;
  }

  .title {
    font-size: 1.5em;
  }

  .nav-button,
  .submit-button {
    padding: 8px 16px;
    font-size: 0.9em;
  }
  .introduction {
    text-align: center;
    padding: 20px;
    max-width: 720px;
    margin: auto;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    height: 100%;
  }

  .introduction .content {
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: left;
    color: #444;
  }

  .start-button {
    padding: 12px 24px;
    font-size: 1.1em;
    border: none;
    border-radius: 6px;
    background-color: #76c7c0;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .start-button:hover {
    background-color: #5ba9a1;
  }
}
.qita {
  height: 0.3rem;
  width: 50%;
}
.qita2 {
  margin-top: 10px;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  padding: 5px;
  border: #ccc 1px solid;
}
</style>
